<template>
  <div>
    <PickupStep1 v-if="pickupStep === 1" :selected="selected" @onUpdate="onUpdate" />
    <PickupStep2 v-else-if="pickupStep === 2" :selected="selected" @onUpdate="onUpdate" />
    <PickupStep3 v-else-if="pickupStep === 3" :selected="selected" @onUpdate="onUpdate" />
    <PickupStep4 v-else-if="pickupStep === 4" :selected="selected" @onUpdate="onUpdate" />
  </div>
</template>

<script>

import { httpClient } from "@/libs/http";

export default {
  components: {
    PickupStep1: () => import('@/components/order_online/PickupStep1'),
    PickupStep2: () => import('@/components/order_online/PickupStep2'),
    PickupStep3: () => import('@/components/order_online/PickupStep3'),
    PickupStep4: () => import('@/components/order_online/PickupStep4'),
  },
  name: "Pickup",
  data: () => ({
    selected: {},
  }),
  computed: {
    pickupStep() {
      if (!this.selected.id_pos) {
        return 1
      } else if (!this.selected.pickup_type && this.selected.pickup_type !== 0 && (!this.selected.order_ids || this.selected.order_ids.length === 0)) {
        return 2
      } else if (!this.selected.id_command) {
        return 3
      } else {
        return 4
      }
    }
  },
  methods: {
    onUpdate(data) {
      this.selected = { ...data }
    },
    async getActiveCommand() {
      if (this.isLoading) {
        this.isLoading = false
        this.$vToastify.warning(this.$t('messages.loading'));
        return false
      }
      this.isLoading = true

      try {
        const { data } = await httpClient.post('/order-online-pickup-processing')
        this.selected = { ...this.selected, id_pos: data.id_pos, process_type: data.process_type, pickup_type: data.command_type, id_command: data.id }
        this.isLoading = false
      } catch (e) {
        this.isLoading = false
      }
    },

    listenSocket() {
      window.socket.on('pickup_command_id', data => {
        if (data.id) {
          this.getActiveCommand()
        }
      })
      window.socket.on('pickup_command_create_failed', data => {
        if (data.content) {
          this.$vToastify.error(data.content)
          this.$root.$emit('playErrorAudio')
        }
      })
    },
  },
  mounted() {
    this.getActiveCommand()
    this.listenSocket()
  }
}
</script>

<style scoped></style>
